import {
  availableInternationalization,
  defaultInternationalization,
  Languages,
} from 'corporate-types';

import { fetchStrapiAPI } from './api';
import { getCompany } from './company';
import { Companies, Pages } from '../models/domain/enum';
import { mergeObjects } from 'corporate-utils';
import { LocalizedPaths } from '../models/domain/type';

export async function getLocalizedPage(targetLocale: string, pageContext: any) {
  const localization = pageContext?.localizations?.data?.find?.(
    (localization: any) => localization?.attributes?.locale === targetLocale
  );
  const localePage = await fetchStrapiAPI(
    `/pages/${localization?.id || defaultInternationalization}`
  );
  return localePage;
}

export function localizePath(page: any, locale: any) {
  const { defaultLocale, slug, localizations } = page || {};
  const defaultlocaleSlug = localizations?.data?.find?.(
    (currentLocale: any) => {
      return currentLocale?.attributes?.locale === defaultLocale;
    }
  );

  if (locale === defaultLocale) {
    // The default locale is not prefixed
    if (defaultlocaleSlug) return `${defaultlocaleSlug?.attributes?.slug}`;
    else return `${slug}`;
  } else {
    const localeSlug = localizations?.data?.find?.((currentLocale: any) => {
      return currentLocale?.attributes?.locale === locale;
    });
    if (localeSlug) return `${localeSlug.attributes?.slug}`;
    else {
      if (page.locale === locale) return slug;
      else return '';
    }
  }
  // The slug should have a localePrefix
}

// forceProductsAlternateLocalesToGoToHomepage il flag indica che per quella lingua non c'è corrispondenza tra link dinamici dei prodotti e quindi è necessario andare alla home
export function getLocalizedPaths(
  page: {
    locale: string;
    locales: string[] | undefined;
    defaultLocale: string | undefined;
    slug: any;
    localizations: any;
  },
  prodsAltLocGoToHomeCurrentLocale: boolean, // il flag prodsAltLocGoToHome del CMS della lingua corrente
  prodsAltLocGoToHome: {
    // il flag prodsAltLocGoToHome per ogni lingua diversa da quella corrente
    locale: Languages;
    prodsAltLocGoToHome: boolean;
  }[],
  catsAltLocGoToHomeCurrentLocale: boolean, // il flag catsAltLocGoToHome del CMS della lingua corrente
  catsAltLocGoToHome: {
    // il flag catsAltLocGoToHome per ogni lingua diversa da quella corrente
    locale: Languages;
    catsAltLocGoToHome: boolean;
  }[],
  pageType: Pages
): LocalizedPaths {
  const localeGlobal = page?.locale;
  const paths = page?.locales
    ?.map((locale: any) => {
      if (locale !== 'default') {
        const objLocalesProds = prodsAltLocGoToHome.find((pal) => {
          return locale === pal?.locale;
        });

        const objLocalesCats = catsAltLocGoToHome.find((pal) => {
          return locale === pal?.locale;
        });

        switch (true) {
          // la pagina è di tipo prodotto
          // e il flag in CMS della lingua corrente (per esempio it-IT) è false
          // se per le altre lingue il flag era a true allora devo fare redirect alla home
          // per esempio è a true in en-US allora le altre lingue devono andare alla home di en-US
          case pageType === Pages.PRODUCT &&
            objLocalesProds &&
            objLocalesProds.prodsAltLocGoToHome &&
            !prodsAltLocGoToHomeCurrentLocale:
            return {
              locale: locale,
              href: '',
            };
          // la pagina è di tipo prodotto
          // e il flag in CMS della lingua corrente (per esempio it-IT) è true
          // significa che i prodotti delle altre lingue non hanno corrispondenza con il mio
          // allora mando alla home
          case pageType === Pages.PRODUCT &&
            prodsAltLocGoToHomeCurrentLocale &&
            locale !== localeGlobal:
            return {
              locale: locale,
              href: '',
            };

          // la pagina è di tipo prodotto
          // e il flag in CMS della lingua corrente (per esempio it-IT) è false
          // se per le altre lingue il flag era a true allora devo fare redirect alla home
          // per esempio è a true in en-US allora le altre lingue devono andare alla home di en-US
          case (pageType === Pages.CATEGORY ||
            pageType === Pages.CATEGORY_FATHER) &&
            objLocalesCats &&
            objLocalesCats.catsAltLocGoToHome &&
            !catsAltLocGoToHomeCurrentLocale:
            return {
              locale: locale,
              href: '',
            };
          // la pagina è di tipo prodotto
          // e il flag in CMS della lingua corrente (per esempio it-IT) è true
          // significa che i prodotti delle altre lingue non hanno corrispondenza con il mio
          // allora mando alla home
          case (pageType === Pages.CATEGORY ||
            pageType === Pages.CATEGORY_FATHER) &&
            catsAltLocGoToHomeCurrentLocale &&
            locale !== localeGlobal:
            return {
              locale: locale,
              href: '',
            };
          default:
            return {
              locale: locale,
              href: localizePath({ ...page }, locale),
            };
        }
      }
    })
    .filter((path: any) => !!path);

  return paths as LocalizedPaths;
}

export async function getTranslations(locale: string) {
  let translations = null;

  switch (locale) {
    case Languages.ITALIAN:
    case availableInternationalization.ITALIAN_ITALY: {
      // eslint-disable-next-line no-case-declarations
      const defaulti18nJson = await import('../i18n/it.json');
      // eslint-disable-next-line no-case-declarations
      let overrides = {};
      switch (getCompany()) {
        case Companies.FELSINEA:
          overrides = await import(
            `../i18n/theme-overrides/${Companies.FELSINEA}_it.json`
          );
          break;
        default:
          overrides = {};
          break;
      }
      translations = mergeObjects(
        defaulti18nJson,
        JSON.parse(JSON.stringify(overrides))
      );
      break;
    }
    case Languages.ENGLISH:
    case availableInternationalization.ENGLISH_UNITED_KINGDOM:
    case availableInternationalization.ENGLISH_UNITED_STATES: {
      // eslint-disable-next-line no-case-declarations
      const defaulti18nJson = await import('../i18n/en.json');
      // eslint-disable-next-line no-case-declarations
      let overrides = {};
      switch (getCompany()) {
        case Companies.FELSINEA:
          overrides = await import(
            `../i18n/theme-overrides/${Companies.FELSINEA}_en.json`
          );
          break;
        default:
          overrides = {};
          break;
      }
      translations = mergeObjects(
        defaulti18nJson,
        JSON.parse(JSON.stringify(overrides))
      );
      break;
    }
    case Languages.GERMAN:
    case availableInternationalization.GERMAN_GERMANY: {
      // eslint-disable-next-line no-case-declarations
      const defaulti18nJson = await import('../i18n/de.json');
      // eslint-disable-next-line no-case-declarations
      let overrides = {};
      switch (getCompany()) {
        case Companies.FELSINEA:
          overrides = await import(
            `../i18n/theme-overrides/${Companies.FELSINEA}_de.json`
          );
          break;
        default:
          overrides = {};
          break;
      }
      translations = mergeObjects(
        defaulti18nJson,
        JSON.parse(JSON.stringify(overrides))
      );
      break;
    }
    case Languages.SPANISH:
    case availableInternationalization.SPANISH_SPAIN: {
      // eslint-disable-next-line no-case-declarations
      const defaulti18nJson = await import('../i18n/es.json');
      // eslint-disable-next-line no-case-declarations
      let overrides = {};
      switch (getCompany()) {
        case Companies.FELSINEA:
          overrides = await import(
            `../i18n/theme-overrides/${Companies.FELSINEA}_es.json`
          );
          break;
        default:
          overrides = {};
          break;
      }
      translations = mergeObjects(
        defaulti18nJson,
        JSON.parse(JSON.stringify(overrides))
      );
      break;
    }
    case Languages.FRENCH:
    case availableInternationalization.FRENCH_FRANCE: {
      // eslint-disable-next-line no-case-declarations
      const defaulti18nJson = await import('../i18n/fr.json');
      // eslint-disable-next-line no-case-declarations
      let overrides = {};
      switch (getCompany()) {
        case Companies.FELSINEA:
          overrides = await import(
            `../i18n/theme-overrides/${Companies.FELSINEA}_fr.json`
          );
          break;
        default:
          overrides = {};
          break;
      }
      translations = mergeObjects(
        defaulti18nJson,
        JSON.parse(JSON.stringify(overrides))
      );
      break;
    }
    case Languages.PORTUGUESE:
    case availableInternationalization.PORTUGUESE_BRAZIL:
    case availableInternationalization.PORTUGUESE_PORTUGAL: {
      // eslint-disable-next-line no-case-declarations
      const defaulti18nJson = await import('../i18n/pt.json');
      // eslint-disable-next-line no-case-declarations
      let overrides = {};
      switch (getCompany()) {
        case Companies.FELSINEA:
          overrides = await import(
            `../i18n/theme-overrides/${Companies.FELSINEA}_pt.json`
          );
          break;
        default:
          overrides = {};
          break;
      }
      translations = mergeObjects(
        defaulti18nJson,
        JSON.parse(JSON.stringify(overrides))
      );
      break;
    }
    case Languages.RUSSIAN:
    case availableInternationalization.RUSSIAN_RUSSIA: {
      // eslint-disable-next-line no-case-declarations
      const defaulti18nJson = await import('../i18n/ru.json');
      // eslint-disable-next-line no-case-declarations
      let overrides = {};
      switch (getCompany()) {
        case Companies.FELSINEA:
          overrides = await import(
            `../i18n/theme-overrides/${Companies.FELSINEA}_ru.json`
          );
          break;
        default:
          overrides = {};
          break;
      }
      translations = mergeObjects(
        defaulti18nJson,
        JSON.parse(JSON.stringify(overrides))
      );
      break;
    }
    case Languages.CHINESE:
    case Languages.CHINESE_SIMPLIFIED:
    case availableInternationalization.CHINESE_SIMPLIFIED_CHINA: {
      // eslint-disable-next-line no-case-declarations
      const defaulti18nJson = await import('../i18n/zh.json');
      // eslint-disable-next-line no-case-declarations
      let overrides = {};
      switch (getCompany()) {
        case Companies.FELSINEA:
          overrides = await import(
            `../i18n/theme-overrides/${Companies.FELSINEA}_zh.json`
          );
          break;
        default:
          overrides = {};
          break;
      }
      translations = mergeObjects(
        defaulti18nJson,
        JSON.parse(JSON.stringify(overrides))
      );
      break;
    }
    default: {
      // eslint-disable-next-line no-case-declarations
      const defaulti18nJson = await import('../i18n/en.json');
      // eslint-disable-next-line no-case-declarations
      let overrides = {};
      switch (getCompany()) {
        case Companies.FELSINEA:
          overrides = await import(
            `../i18n/theme-overrides/${Companies.FELSINEA}_en.json`
          );
          break;
        default:
          overrides = {};
          break;
      }
      translations = mergeObjects(
        defaulti18nJson,
        JSON.parse(JSON.stringify(overrides))
      );
      break;
    }
  }

  return translations;
}

export async function getAllTranslations(): Promise<{ [key: string]: any }> {
  const translations = await Promise.all([
    await import('../i18n/it.json', { assert: { type: 'json' } }),
    await import('../i18n/en.json', { assert: { type: 'json' } }),
    await import('../i18n/de.json', { assert: { type: 'json' } }),
    await import('../i18n/es.json', { assert: { type: 'json' } }),
    await import('../i18n/fr.json', { assert: { type: 'json' } }),
    await import('../i18n/pt.json', { assert: { type: 'json' } }),
    await import('../i18n/ru.json', { assert: { type: 'json' } }),
    await import('../i18n/zh.json', { assert: { type: 'json' } }),
  ])
    .then(async ([it, en, de, es, fr, pt, ru, zh]) => {
      const translations = await Promise.all([
        (async () => {
          const defaulti18nJson = JSON.parse(JSON.stringify(it.default));
          let overrides = {};

          switch (getCompany()) {
            case Companies.FELSINEA:
              overrides = await import(
                `../i18n/theme-overrides/${Companies.FELSINEA}_it.json`
              );
              break;

            default:
              overrides = {};
              break;
          }
          const merged = mergeObjects(
            defaulti18nJson,
            JSON.parse(JSON.stringify(overrides))
          );
          return merged;
        })(),
        (async () => {
          const defaulti18nJson = JSON.parse(JSON.stringify(en.default));
          let overrides = {};

          switch (getCompany()) {
            case Companies.FELSINEA:
              overrides = await import(
                `../i18n/theme-overrides/${Companies.FELSINEA}_en.json`
              );
              break;

            default:
              overrides = {};
              break;
          }

          return mergeObjects(
            defaulti18nJson,
            JSON.parse(JSON.stringify(overrides))
          );
        })(),
        (async () => {
          const defaulti18nJson = JSON.parse(JSON.stringify(de.default));
          let overrides = {};

          switch (getCompany()) {
            case Companies.FELSINEA:
              overrides = await import(
                `../i18n/theme-overrides/${Companies.FELSINEA}_de.json`
              );
              break;

            default:
              overrides = {};
              break;
          }

          return mergeObjects(
            defaulti18nJson,
            JSON.parse(JSON.stringify(overrides))
          );
        })(),
        (async () => {
          const defaulti18nJson = JSON.parse(JSON.stringify(es.default));
          let overrides = {};

          switch (getCompany()) {
            case Companies.FELSINEA:
              overrides = await import(
                `../i18n/theme-overrides/${Companies.FELSINEA}_es.json`
              );
              break;

            default:
              overrides = {};
              break;
          }

          return mergeObjects(
            defaulti18nJson,
            JSON.parse(JSON.stringify(overrides))
          );
        })(),
        (async () => {
          const defaulti18nJson = JSON.parse(JSON.stringify(fr.default));
          let overrides = {};

          switch (getCompany()) {
            case Companies.FELSINEA:
              overrides = await import(
                `../i18n/theme-overrides/${Companies.FELSINEA}_fr.json`
              );
              break;

            default:
              overrides = {};
              break;
          }

          return mergeObjects(
            defaulti18nJson,
            JSON.parse(JSON.stringify(overrides))
          );
        })(),
        (async () => {
          const defaulti18nJson = JSON.parse(JSON.stringify(pt.default));
          let overrides = {};

          switch (getCompany()) {
            case Companies.FELSINEA:
              overrides = await import(
                `../i18n/theme-overrides/${Companies.FELSINEA}_pt.json`
              );
              break;

            default:
              overrides = {};
              break;
          }

          return mergeObjects(
            defaulti18nJson,
            JSON.parse(JSON.stringify(overrides))
          );
        })(),
        (async () => {
          const defaulti18nJson = JSON.parse(JSON.stringify(ru.default));
          let overrides = {};

          switch (getCompany()) {
            case Companies.FELSINEA:
              overrides = await import(
                `../i18n/theme-overrides/${Companies.FELSINEA}_ru.json`
              );
              break;

            default:
              overrides = {};
              break;
          }

          return mergeObjects(
            defaulti18nJson,
            JSON.parse(JSON.stringify(overrides))
          );
        })(),
        (async () => {
          const defaulti18nJson = JSON.parse(JSON.stringify(zh.default));
          let overrides = {};

          switch (getCompany()) {
            case Companies.FELSINEA:
              overrides = await import(
                `../i18n/theme-overrides/${Companies.FELSINEA}_zh.json`
              );
              break;

            default:
              overrides = {};
              break;
          }

          return mergeObjects(
            defaulti18nJson,
            JSON.parse(JSON.stringify(overrides))
          );
        })(),
        (async () => {
          const defaulti18nJson = JSON.parse(JSON.stringify(zh.default));
          let overrides = {};

          switch (getCompany()) {
            case Companies.FELSINEA:
              overrides = await import(
                `../i18n/theme-overrides/${Companies.FELSINEA}_zh.json`
              );
              break;

            default:
              overrides = {};
              break;
          }

          return mergeObjects(
            defaulti18nJson,
            JSON.parse(JSON.stringify(overrides))
          );
        })(),
      ]);
      return translations;
    })
    .then(([it, en, de, es, fr, pt, ru, zh]) => {
      return {
        [Languages.ITALIAN]: JSON.parse(JSON.stringify(it)),
        [Languages.ENGLISH]: JSON.parse(JSON.stringify(en)),
        [Languages.GERMAN]: JSON.parse(JSON.stringify(de)),
        [Languages.SPANISH]: JSON.parse(JSON.stringify(es)),
        [Languages.FRENCH]: JSON.parse(JSON.stringify(fr)),
        [Languages.PORTUGUESE]: JSON.parse(JSON.stringify(pt)),
        [Languages.RUSSIAN]: JSON.parse(JSON.stringify(ru)),
        [Languages.CHINESE]: JSON.parse(JSON.stringify(zh)),
        [Languages.CHINESE_SIMPLIFIED]: JSON.parse(JSON.stringify(zh)),
      };
    });
  return translations;
}
